import React, { Component } from "react";
import CryptoJs from 'crypto-js';
import ReactDOM from 'react-dom';
import { Redirect } from "react-router-dom";
import "../config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';

const loader = document.querySelector('.loader');

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove('loader--hide');

const hideLoader = () => loader.classList.add('loader--hide');
class Login extends Component{
    
    constructor(props){
        super(props);
        this.state={
            username:'',
            password:''
        }
        this.handleChange= this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }

    handleChange = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }
    handleSubmit = e => {
        e.preventDefault();
        $('.btn-block').attr('disabled',true);
        var username =this.state.username;
        var password =this.state.password;
        //let password = CryptoJs.AES.encrypt(this.state.password, global.config.config.secretKey_pwd).toString();
        var dataToServer = [];
        dataToServer.push({ 'username': username, 'password': password, 'baseUrl': global.config.config.basePath});
console.log(global.config.api.remotePath + 'user/login', 787878787);
        fetch(global.config.api.remotePath + 'user/login', {
            method: 'POST',
            body: JSON.stringify(dataToServer),
            headers: {
                'content-type': 'application/json'
            }
        }).then((res) => res.json())
            .then(data => {
                console.log(data);
                if (data.status==2){ /// first time login
                    
                    toast.info("One mail send to your email address for changing password!");
                    //window.location.href = '/admin-login';

                } else if(data.status==1){
                        var response = data.result;
                        var userID = '';
                        var firstName = '';
                        var lastName = '';
                        var email = '';
                        var title = '';
                        var type = '';
                        response.forEach(element => {
                            console.log(element);
                            userID = element.pms_user_id;
                            firstName = element.name;
                            //lastName = element.name;
                            email = element.user_email;
                            //title = element.title;
                            type = element.user_type;
                        });
                        //console.log(userID);
                    localStorage.setItem('userID', userID);
                        localStorage.setItem('firstName', firstName);
                        localStorage.setItem('lastName', lastName);
                        localStorage.setItem('email', email);
                        localStorage.setItem('title', title);
                        localStorage.setItem('type', type);
                        window.location.href = '/admin-dashboard';
                } else{
                    toast.error( "Wrong username or password");
                    window.location.href = '/admin-login';
                    $('.btn-block').attr('disabled', false);
                }
                $('.btn-block').attr('disabled', false);
            }).catch(err => {
                toast.error("Wrong username or password");
                console.log(err, 67676)
                $('.btn-block').attr('disabled', false);
            })

    };
    render() {
        return (
            <div>
                <section>
                    <div class="wrapper">
                        <ToastContainer />
                        <form class="form-signin" action="#" onSubmit={this.handleSubmit}>
                            <h2 class="form-signin-heading">Sign into Dashboard</h2>
                            <div class="form-group">
                                <label for="email">Email*</label>
                                <input type="text" class="form-control" name="username" placeholder="Email Address" required="" autofocus="" value={this.state.username} onChange={this.handleChange} />
                            </div>
                            <div class="form-group">
                                <label for="password">Password*</label>
                                <input type="password" class="form-control" name="password" required="" autofocus="" value={this.state.password} onChange={this.handleChange} />
                            </div>
                            <button class="btn log btn-block" type="submit">Login</button>   
                            <div className="form-group forget_password">
                                <a className="forget_password" href="/forget-password">Forget Password</a>
                            </div>
                            
                        </form>
                        
                    </div>
                </section>
            </div>
        );

    };
}
export default Login;