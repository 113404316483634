import React, { Component } from 'react';

class App extends Component {
  formatDate(dateString: string) {
    const options: Intl.DateTimeFormatOptions = { //Typescript ways of adding the type
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString([], options);
  };
}
export default App;