module.exports = global.config = {
    api: {
        remotePath: 'https://performanceapi.maxmobility.in/api/v1/'
        // remotePath: 'http://192.168.0.140:8000/api/v1/'
        // remotePath: 'http://192.168.0.6:8000/api/v1/'
       
        //remotePath: 'http://localhost:8000/api/v1/'
        //remotePath: 'http://15.207.190.170:8000/api/v1/' //for urs digitaly
        // rest of your translation object
    },
    config:{
        expenditure: 3200,
        basePath:"http://localhost:3000",
        // basePath:"http://performance.maxmobility.in",
        secretKey_pwd:"_zefdsuh123",
    }
    // other global config variables you wish
/* "homepage": "http://pmsadmin.maxmobility.in", */
};