import React, { useEffect } from 'react';
import  Login  from "./views/Login";
import { Sidebar } from "./component/Sidebar";
import  Dasboard  from "./views/Dasboard";
import  ProjectLogs  from "./views/ProjectLogs";
import  Logout  from "./views/Logout";
import { Route, Switch,Redirect } from "react-router-dom";
import Wbsupload from "./views/Wbsupload";
import ResourcePermormace from './views/ResourcePerformace';
import ChangePassword from './views/ChangePassword';
import ForgetPassword from './views/ForgetPassword';


const Routes = function() {
    var isLogin=0;
    const NavbarShow = function () {
        const currentRoute = window.location.pathname;
        if (currentRoute !== '/admin-login') { return <Sidebar />; } else { return '' };
    }
    const PrivateRoute = function ({ children, ...rest }){
        //alert('hello');
        const userID=localStorage.getItem('userID');
        if(!userID){
            window.location.href = '/admin-login';
        } else{
            return (
                <Route
                    {...rest}
                    render={({ location }) =>
                        (
                            children
                        ) 
                    }
                />
            );
        }
    }

    return(
        <div>
            <Sidebar  />
            <Switch>
                <Route exact path="/admin-login" component={Login} />
                <Route exact path="/admin-logout" component={Logout} />
                <Route exact path="/change-password/:code" component={ChangePassword} />
                <Route exact path="/forget-password" component={ForgetPassword} />
                <Route exact path="/">
                    <Redirect to="/admin-login"></Redirect>
                </Route>
                <PrivateRoute path="/admin-dashboard" component={Dasboard} />
                <PrivateRoute path="/project-logs" component={ProjectLogs} />
                <PrivateRoute path="/wbs-upload" component={Wbsupload} />
                <PrivateRoute path="/performance" component={ResourcePermormace} />
            </Switch>
        </div>
        
    );

};

export default Routes;