import React from 'react';
import ReactDOM from 'react-dom';
// import theme css
import './assets/css/style.css';
import App from './App';
/* import 'bootstrap/dist/css/bootstrap.min.css'; */
// import 'jquery/dist/jquery.min.js';
// import "datatables.net-dt/js/dataTables.dataTables"
// import "datatables.net-dt/css/jquery.dataTables.min.css"
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import Routes from './Routes'; // where we are going to specify our routes
console.log(window.location.pathname);
ReactDOM.render(
  <Router>
    <Routes />
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
