import React, { useEffect, useState } from 'react';

export const Sidebar = () => {
    const [isLoggedIn, setisLoggedIn] = useState(false)
    useEffect(() => {
        const currentRoute = window.location.pathname;
        if (currentRoute !== '/admin-login') { 
            setisLoggedIn(true)
         } else { 
            setisLoggedIn(false)
          };
    }, [])
    return (
            (isLoggedIn) ? (
                <>
                    
                </>
            ) :""
    )
};