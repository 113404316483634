import React, { Component } from "react";
import Header from "../component/Header";
import Loader from "react-loader-spinner";
import Moment from 'react-moment';
import moment from 'moment';
import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";
import { CSVLink, CSVDownload } from "react-csv";
import $ from 'jquery';
import { nativeTouchData } from "react-dom/test-utils";

class ResourcePermormace extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            performance: [],
            budgetShow: true,
            detailPerformance: [],
            modalTitle: 'Performance Details',
            budgetdeviationCount: 0,
            totalActualMan: 0,
            totalActualdev: 0,
            totalAssiTicket: 0,
            totalCloseTicket: 0,
            totalPendingTicket: 0,
            totalPendingTicketMan: 0,
            totalActualBudget: 0,
            budgetdeviation: 0,
            totalBudget: 0,
            totalEstMan: 0,
            totalActClose: 0,
            totalActPending: 0,
            estManPending: 0,
            totaldevInPercentage: 0,
            csvData: [],
            csvDataDetails: [],
            fromDate: '',
            toDate: '',
            leaderDrops: [],
            projectDrops: [],
            resourceId: 0,
            leaderId: 0,
            projectId: 0,
            est1_rev: false,
            actual1_rev: false,
            est2_rev: false,
            actual2_rev: false,
            estb1_rev: false,
            actualb1_rev: false,
            estb2_rev: false,
            actualb2_rev: false,
            working_rev: false,
            name_rev: false,
            userId: 0,
            year: 0,
            fromDate: '',
            toDate: ''
        }
    }
    getLeaders = () => {
        return new Promise((resolve, reject) => {
            fetch(global.config.api.remotePath + 'user/leader', {
                method: 'get',
                headers: {
                    'content-type': 'application/json'
                }
            }).then((res) => res.json())
                .then(data => {
                    console.log(data, "getLeaders");
                    if (data) {
                        resolve({ success: true, data: data })
                    }
                    else {
                        resolve({ success: false, data: [] })
                    }
                })
                .catch(err => {
                    resolve({ success: false, err: err })
                })
        })
    }
    geProjects = () => {
        return new Promise((resolve, reject) => {
            fetch(global.config.api.remotePath + 'user/get-project-by-resource?user_id=' + this.state.userId, {
                method: 'get',
                headers: {
                    'content-type': 'application/json'
                }
            }).then((res) => res.json())
                .then(data => {
                    console.log(data, "geProjects");
                    if (data) {
                        resolve({ success: true, data: data })
                    }
                    else {
                        resolve({ success: false, data: [] })
                    }
                })
                .catch(err => {
                    resolve({ success: false, err: err })
                })
        })
    }
    getResourcePerformance = (filterData) => {
        console.log(filterData, 3333);
        return new Promise((resolve, reject) => {
            fetch(global.config.api.remotePath + "user/resource-performance", {
                method: 'post',
                body: JSON.stringify(filterData),
                headers: {
                    'content-type': 'application/json'
                }
            }).then((res) => res.json())
                .then(data => {
                    console.log(data, "getResourcePerformance");
                    if (data) {
                        resolve({ success: true, data: data })
                    } else {
                        resolve({ success: false, data: [] })
                    }
                }).catch(err => {
                    resolve({ success: false, err: err })
                })
        });
    }
    /* getResourcePerformance=()=>{
        return new Promise((resolve,reject)=>{
            fetch(global.config.api.remotePath +"user/performance-cumulative",{
                method:'get',
                headers:{
                    'content-type': 'application/json'
                }
            }).then((res) => res.json())
            .then(data => {
                if(data){
                    resolve({ success: true, data: data })
                } else{
                    resolve({ success: false, data: [] })
                }
            }).catch(err=>{
                resolve({ success: false, err: err })
            })
        });
    } */
    getperformanceFilter = (filterData) => {
        return new Promise((resolve, reject) => {
            fetch(global.config.api.remotePath + "user/resource-performance", {
                method: 'post',
                body: JSON.stringify(filterData),
                headers: {
                    'content-type': 'application/json'
                }
            }).then((res) => res.json())
                .then(data => {
                    console.log(data, "getperformanceFilter");
                    if (data) {
                        resolve({ success: true, data: data })
                    } else {
                        resolve({ success: false, data: [] })
                    }
                }).catch(err => {
                    resolve({ success: false, err: err })
                })
        });
    }

    getDetailPerformance = (filterData) => {
        return new Promise((resolve, reject) => {
            fetch(global.config.api.remotePath + "user/resource-performance-details", {
                method: 'post',
                body: JSON.stringify(filterData),
                headers: {
                    'content-type': 'application/json'
                }
            }).then((res) => res.json())
                .then(data => {
                    console.log(data, "getDetailPerformance");
                    if (data) {
                        resolve({ success: true, data: data })
                    } else {
                        resolve({ success: false, data: [] })
                    }
                }).catch(err => {
                    resolve({ success: false, err: err })
                })
        });
    }

    getDetailFilter = (filterData) => {
        console.log(filterData, "filterData getDetailFilter");

        return new Promise((resolve, reject) => {
            fetch(global.config.api.remotePath + "user/resource-performance-details", {
                method: 'post',
                body: JSON.stringify(filterData),
                headers: {
                    'content-type': 'application/json'
                }
            }).then((res) => res.json())
                .then(data => {
                    console.log(data, "getDetailFilter");
                    if (data) {
                        resolve({ success: true, data: data })
                    } else {
                        resolve({ success: false, data: [] })
                    }
                }).catch(err => {
                    console.log(err, "err getDetailFilter");

                    resolve({ success: false, err: err })
                })
        });
    }

    mappedApiDatWithCalculation = (data) => {
        if (data) {
            this.setState({ performance: data.data });
        }

    }
    mappedApiDatWithCalculationDetail = (data) => {
        if (data) {
            this.setState({ detailPerformance: data.data });
        }

    }
    footerTotalCalculation = (data) => {
        if (data == {} || data.data == {} || !data.data || !data) {
            this.setState({
                budgetdeviationCount: 0,
                totalActualMan: 0,
                totalActualdev: 0,
                totalAssiTicket: 0,
                totalCloseTicket: 0,
                totalPendingTicket: 0,
                totalPendingTicketMan: 0,
                totalActualBudget: 0,
                budgetdeviation: 0,
                totalBudget: 0,
                totalEstMan: 0,
                totalActClose: 0,
                totalActPending: 0,
                estManPending: 0,
            });
            return false;
        }
        return new Promise(resolve => {
            let budgetSum = 0;
            let EstManSum = 0;
            let actualManSum = 0;
            let actualdevSum = 0;
            let actualdevSumCount = 0;
            let devInPercentagesum = 0;
            let devInPercentagesumCount = 0;
            let assiticketSum = 0;
            let closeticketSum = 0;
            let pendingticketSum = 0;
            let pendingmanSum = 0;
            let actualBudget = 0;
            let budgetDeviationSum = 0;
            let budgetdeviationCountsum = 0;
            let totalActClosesum = 0;
            let totalActPendingsum = 0;
            let estManPendingsum = 0;

            let expenditure = global.config.api.expenditure;

            data.data.forEach((element) => {
                //console.log('dataelement',element);
                if (element.estimated_budget != '0.00' && element.estimated_budget != null) {
                    budgetSum += parseFloat(element.estimated_budget);
                }
                !!(element.estimated_man_day) ? EstManSum += parseFloat(element.estimated_man_day) : EstManSum += 0;
                !!(element.actual_man_day_on_close_tickets) ? totalActClosesum += parseFloat(element.actual_man_day_on_close_tickets) : totalActClosesum += 0;
                !!(element.actual_man_day_on_pending_tickets) ? totalActPendingsum += parseFloat(element.actual_man_day_on_pending_tickets) : totalActPendingsum += 0;
                !!(element.pending_tickets_estimated_man_day) ? estManPendingsum += parseFloat(element.pending_tickets_estimated_man_day) : estManPendingsum += 0;
                !!(element.actual_man_day) ? actualManSum += parseFloat(element.actual_man_day) : actualManSum += 0;

                if (element.deviation_in_man_day != 0 && element.deviation_in_percentage != '' && element.deviation_in_percentage != undefined) {
                    actualdevSumCount++;
                    actualdevSum += parseFloat(element.deviation_in_man_day);
                }
                if (element.deviation_in_percentage != 0 && element.deviation_in_percentage != '' && element.deviation_in_percentage != undefined && element.deviation_in_percentage != null) {
                    devInPercentagesumCount++;
                    devInPercentagesum += parseFloat(element.deviation_in_percentage);
                }
                if (element.total_tickets != 'N/A') {
                    assiticketSum += parseFloat(element.total_tickets);
                }

                if (element.close_tickets != 'N/A') {
                    closeticketSum += parseFloat(element.close_tickets);
                }

                if (element.pending_tickets != 'N/A' && parseFloat(element.pending_tickets) > 0) {
                    pendingticketSum += parseFloat(element.pending_tickets);
                }

                if (element.pending_tickets_estimated_man_day != 'N/A' && parseFloat(element.pending_tickets_estimated_man_day) > 0) {
                    pendingmanSum += parseFloat(element.pending_tickets_estimated_man_day);
                }

                if (element.actual_budget != 'N/A' && element.actual_budget != null) {
                    actualBudget += parseFloat(element.actual_budget);
                }
                if (element.deviation_in_budget != 'N/A' && element.deviation_in_budget != null && element.deviation_in_budget != 0) {
                    budgetDeviationSum += parseFloat(element.deviation_in_budget);
                    budgetdeviationCountsum += 1;
                }

                this.setState({
                    budgetdeviationCount: budgetdeviationCountsum,
                    totalActualMan: actualManSum,
                    totalActualdev: (actualdevSumCount != 0) ? actualdevSum / actualdevSumCount : 0,
                    totaldevInPercentage: (devInPercentagesumCount != 0) ? devInPercentagesum / devInPercentagesumCount : 0,
                    totalAssiTicket: assiticketSum,
                    totalCloseTicket: closeticketSum,
                    totalPendingTicket: pendingticketSum,
                    totalPendingTicketMan: pendingmanSum,
                    totalActualBudget: actualBudget,
                    budgetdeviation: (budgetDeviationSum != 0 || budgetdeviationCountsum != 0) ? budgetDeviationSum / budgetdeviationCountsum : 0,
                    totalBudget: budgetSum,
                    totalEstMan: EstManSum,
                    totalActClose: totalActClosesum,
                    totalActPending: totalActPendingsum,
                    estManPending: estManPendingsum,
                });
            });
            resolve({ success: true })
        });
    }

    componentDidMount = async () => {
        this.setState({ isLoading: true });
        this.getLeaders().then(data => {
            //console.log("projects", data.data.data)
            this.setState({ leaderDrops: data.data.data });

        });
        // console.log(moment().month(),1);
        if (moment().month() > 4) {
            // var startDateOfTheYear = new Date(moment().month('April').startOf('month').format('YYYY-MM-DD'), "mm")
            var startDateOfTheYear = new Date(moment().month('April').startOf('month').format('YYYY-MM-DD')); // added by ayan
        } else {
            var startDateOfTheYear = new Date(moment().subtract('year').month('April').startOf('month').format('YYYY-MM-DD'))
            //var endDateOfTheYear = new Date(moment().month('March').endOf('month').format('YYYY-MM-DD'))
        }

        var endDateOfTheYear = new Date(moment().format('YYYY-MM-DD'));
        console.log(startDateOfTheYear, "lklklkllk", endDateOfTheYear);

        await this.setState({ fromDate: startDateOfTheYear, toDate: endDateOfTheYear });
        var searchData = [];
        if (localStorage.getItem('type') == 'Member') {

            searchData.push({ 'fromDate': moment(this.state.fromDate).format('YYYY-MM-DD'), 'toDate': moment(this.state.toDate).format('YYYY-MM-DD'), 'leaderId': localStorage.getItem('userID') });
        } else {
            searchData.push({ 'fromDate': moment(this.state.fromDate).format('YYYY-MM-DD'), 'toDate': moment(this.state.toDate).format('YYYY-MM-DD') });
        }
        console.log(searchData, "searchData");
        await this.getResourcePerformance(searchData).then(data => {
            console.log('API data getResourcePerformance', data.data, searchData);
            this.mappedApiDatWithCalculation(data.data);
            this.footerTotalCalculation(data ? data.data ? data.data : {} : {});
        });
        this.setState({ isLoading: false });
    }

    showdetail = async (resourceId, resourceName) => {
        var searchData = [];
        this.setState({ isLoading: true, modalTitle: "Performance details of " + resourceName, userId: resourceId });
        if (localStorage.getItem('type') == 'Member') {
            searchData.push({ 'userId': resourceId, 'fromDate': moment(this.state.fromDate).format('YYYY-MM-DD'), 'toDate': moment(this.state.toDate).format('YYYY-MM-DD'), 'leaderId': localStorage.getItem('userID') });
        } else {
            searchData.push({ 'userId': resourceId, 'fromDate': moment(this.state.fromDate).format('YYYY-MM-DD'), 'toDate': moment(this.state.toDate).format('YYYY-MM-DD') });
        }
        await this.getDetailFilter(searchData).then(data => {
            //console.log('API data',data.data);
            this.mappedApiDatWithCalculationDetail(data ? data.data ? data.data : {} : {});
            this.footerTotalCalculation(data ? data.data ? data.data : {} : {});
            this.setState({ projectDrops: data.data.data ? data.data.data : {} });
            this.setState({ leaderDrops: data.data.data ? data.data.data : {} });
            let pp = this.state.leaderDrops.filter((ele, ind) => ind === this.state.leaderDrops.findIndex(elem => elem.leader_id === ele.leader_id));
            this.setState({ leaderDrops: pp ? pp : {} });
        });

        this.setState({ isLoading: false });

        $('.budget_hide').show();
        //$('.budget_hide').find('.modal-body').html(html);
    }

    showDetailClose = () => {
        $('.budget_hide').hide();
        // window.location.reload();
    }

    PerformanceCsvDownload = () => {
        const { performance } = this.state;
        let csvArray = [];

        var headers = ["Name", "Burn Down Rate", "calender days", "Estimated Man Days", "Actual mandays on close", "Actual mandays on pending", "Estimated mandays on pending", "Actual Man Days", "Deviation in Man Days", "Deviation in Percentage", "Estimated Budget", "Actual Budget", "Deviation", "Estimated end date", "Actual end date", "Deviation in Dates", "Assign Tickets", "Close Tickets", "Pending Tickets"];
        //csvArray=csvArray.bind(headers);
        csvArray.push(headers);
        if (performance.length) {
            performance.forEach((item) => {
                var temArr = [item.resource_name, item.actual_man_day_per_tickets, item.calendar_day, item.estimated_man_day, item.actual_man_day_on_close_tickets, item.actual_man_day_on_pending_tickets, item.pending_tickets_estimated_man_day, item.actual_man_day, item.deviation_in_man_day, item.deviation_in_percentage, item.estimated_budget, item.actual_budget, item.deviation_in_budget, moment(item.estimated_end_date).format('DD-MM-YYYY'), moment(item.actual_end_date).format('DD-MM-YYYY'), item.date_deviation, item.total_tickets, item.close_tickets, item.pending_tickets];
                csvArray.push(temArr);
            });

        }
        //this.footerTotalCalculation(performance);

        var footerTmp = ['Total', '', ''
            , this.state.totalEstMan.toLocaleString(undefined, { maximumFractionDigits: 2 })
            , this.state.totalActClose.toLocaleString(undefined, { maximumFractionDigits: 2 })
            , this.state.totalActPending.toLocaleString(undefined, { maximumFractionDigits: 2 })
            , this.state.estManPending.toLocaleString(undefined, { maximumFractionDigits: 2 })
            , this.state.totalActualMan.toLocaleString(undefined, { maximumFractionDigits: 2 })
            , this.state.totalActualdev.toLocaleString(undefined, { maximumFractionDigits: 2 })
            , this.state.totaldevInPercentage.toLocaleString(undefined, { maximumFractionDigits: 2 })
            , this.state.totalBudget.toLocaleString(undefined, { maximumFractionDigits: 2 })
            , this.state.totalActualBudget.toLocaleString(undefined, { maximumFractionDigits: 2 })
            , (this.state.budgetdeviation).toLocaleString(undefined, { maximumFractionDigits: 2 })
            , '', '', '', this.state.totalAssiTicket.toLocaleString(undefined, { maximumFractionDigits: 2 }), this.state.totalCloseTicket.toLocaleString(undefined, { maximumFractionDigits: 2 }), this.state.totalPendingTicket.toLocaleString(undefined, { maximumFractionDigits: 2 })]
        csvArray.push(footerTmp);
        this.setState({ csvData: csvArray });
    }

    DetailsCsvDownload = () => {
        const { detailPerformance } = this.state;
        let csvArray = [];

        var headers = ["Leader", "Project", "Estimated Man Days", "Actual mandays on close", "Actual mandays on pending", "Estimated mandays on pending", "Actual Man Days", "Deviation in Man Days", "Deviation in Percentage", "Estimated Budget", "Actual Budget", "Deviation in Budget", "Deviation in Percent", "Assign Tickets", "Close Tickets", "Pending Tickets"];

        csvArray.push(headers);
        if (detailPerformance.length) {
            detailPerformance.forEach((item) => {

                var temArr = [item.leader_name, item.project_name, item.estimated_man_day, item.actual_man_day_on_close_tickets, item.actual_man_day_on_pending_tickets, item.pending_tickets_estimated_man_day, item.actual_man_day, item.deviation_in_man_day, item.deviation_in_percentage, item.estimated_budget, item.actual_budget, item.deviation_in_budget, item.total_tickets, item.close_tickets, item.pending_tickets];

                csvArray.push(temArr);
            });

        }
        //this.footerTotalCalculation(projects);
        var footerTmp = ['Total', ''
            , this.state.totalEstMan.toLocaleString(undefined, { maximumFractionDigits: 2 })
            , this.state.totalActClose.toLocaleString(undefined, { maximumFractionDigits: 2 })
            , this.state.totalActPending.toLocaleString(undefined, { maximumFractionDigits: 2 })
            , this.state.estManPending.toLocaleString(undefined, { maximumFractionDigits: 2 })
            , this.state.totalActualMan.toLocaleString(undefined, { maximumFractionDigits: 2 })
            , this.state.totalActualdev.toLocaleString(undefined, { maximumFractionDigits: 2 })
            , this.state.totaldevInPercentage.toLocaleString(undefined, { maximumFractionDigits: 2 })
            , this.state.totalBudget.toLocaleString(undefined, { maximumFractionDigits: 2 })
            , this.state.totalActualBudget.toLocaleString(undefined, { maximumFractionDigits: 2 })
            , (this.state.budgetdeviation).toLocaleString(undefined, { maximumFractionDigits: 2 })
            , this.state.totalAssiTicket.toLocaleString(undefined, { maximumFractionDigits: 2 }), this.state.totalCloseTicket.toLocaleString(undefined, { maximumFractionDigits: 2 }), this.state.totalPendingTicket.toLocaleString(undefined, { maximumFractionDigits: 2 })]

        csvArray.push(footerTmp);
        this.setState({ csvDataDetails: csvArray });
    }

    periodDropChange = async (event) => {
        if (event.target.value == 7) {
            await this.setState({ dateFilterShow: true, period: event.target.value });
            $('.show_date_filter').show();
        }
        else {
            await this.setState({ dateFilterShow: false, period: event.target.value });
            $('.show_date_filter').hide();
        }
        if (event.target.value == 0) {
            await this.setState({ fromDate: new Date('2016-08-03'), toDate: new Date(moment().format('YYYY-MM-DD')) });
        } else {
            if (event.target.value == 1) {
                var startDateOfTheYear = new Date(moment().startOf('month').format('YYYY-MM-DD'));
                var endDateOfTheYear = new Date(moment().endOf('month').format('YYYY-MM-DD'));
            } else if (event.target.value == 2) {
                var startDateOfTheYear = new Date(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
                var endDateOfTheYear = new Date(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
                //console.log('form date', startDateOfTheYear);

            } else if (event.target.value == 3) {

                var startDateOfTheYear = new Date(moment().quarter(moment().quarter()).startOf('quarter').format('YYYY-MM-DD'));
                var endDateOfTheYear = new Date(moment().quarter(moment().quarter()).endOf('quarter').format('YYYY-MM-DD'));
                //console.log('form date', startDateOfTheYear);

            } else if (event.target.value == 4) {

                var startDateOfTheYear = new Date(moment().quarter(moment().quarter()).subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'));
                var endDateOfTheYear = new Date(moment().quarter(moment().quarter()).subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD'));

            } else if (event.target.value == 5) {
                var startDateOfTheYear = new Date(moment().month('April').startOf('month').format('YYYY-MM-DD'))
                var endDateOfTheYear = new Date(moment().add(1, 'year').month('March').endOf('month').format('YYYY-MM-DD'))
                // alert(1)
                // if (moment().quarter() === 2) {
                //     alert(2)
                //     var startDateOfTheYear = new Date(moment().month('April').startOf('month').format('YYYY-MM-DD'))
                //     var endDateOfTheYear = new Date(moment().add(1, 'year').month('March').endOf('month').format('YYYY-MM-DD'))
                // } else {
                //     alert(3)
                //     var startDateOfTheYear = new Date(moment().subtract(1, 'year').month('April').startOf('month').format('YYYY-MM-DD'))
                //     var endDateOfTheYear = new Date(moment().month('March').endOf('month').format('YYYY-MM-DD'))
                // }
                //console.log('form date', startDateOfTheYear);

            } else if (event.target.value == 6) {

                if (moment().quarter() === 2) {
                    var thisFiscalStart = moment().month('April').startOf('month').format('YYYY-MM-DD')
                    var thisFiscalEnd = moment().add(1, 'year').month('March').endOf('month').format('YYYY-MM-DD')
                } else {
                    var thisFiscalStart = moment().subtract(1, 'year').month('April').startOf('month').format('YYYY-MM-DD')
                    var thisFiscalEnd = moment().month('March').endOf('month').format('YYYY-MM-DD')
                }

                var startDateOfTheYear = new Date(moment(thisFiscalStart).subtract(1, 'year').format('YYYY-MM-DD'))
                var endDateOfTheYear = new Date(moment(thisFiscalEnd).subtract(1, 'year').format('YYYY-MM-DD'))
            }
            var currentDate = new Date(moment().format('YYYY-MM-DD'));
            if (endDateOfTheYear > currentDate) {
                endDateOfTheYear = currentDate;
            }
            console.log(startDateOfTheYear, endDateOfTheYear, 89898);

            await this.setState({ fromDate: startDateOfTheYear, toDate: endDateOfTheYear });
        }

        $('.budget_hide').find('#period').val(event.target.value);
    }

    performanceDataTableSort = (sort) => {
        let performance = this.state.performance
        if (sort == 'estman') {
            let est1_rev = !this.state.est1_rev;
            if (est1_rev) {
                performance.sort((a, b) => b.estimated_man_day - a.estimated_man_day);

            } else {
                performance.sort((a, b) => a.estimated_man_day - b.estimated_man_day);
            }
            this.setState({ est1_rev });

        } else if (sort == 'actualman') {
            let actual1_rev = !this.state.actual1_rev;
            if (actual1_rev) {
                performance.sort((a, b) => b.actual_man_day - a.actual_man_day);

            } else {
                performance.sort((a, b) => a.actual_man_day - b.actual_man_day);
            }
            this.setState({ actual1_rev });


        } else if (sort == 'estbudget') {
            let estb1_rev = !this.state.estb1_rev;
            if (estb1_rev) {
                performance.sort((a, b) => b.estimated_budget - a.estimated_budget);

            } else {
                performance.sort((a, b) => a.estimated_budget - b.estimated_budget);
            }
            this.setState({ estb1_rev });


        } else if (sort == 'actualbudget') {
            let actualb1_rev = !this.state.actualb1_rev;
            if (actualb1_rev) {
                performance.sort((a, b) => b.actual_budget - a.actual_budget);

            } else {
                performance.sort((a, b) => a.actual_budget - b.actual_budget);
            }
            this.setState({ actualb1_rev });
        } else if (sort == 'name') {
            let name_rev = !this.state.name_rev;

            if (name_rev) {
                performance.sort(function (a, b) {
                    return b.resource_name.localeCompare(a.resource_name);
                });
                // performance.sort((a, b) => b.resource_name - a.resource_name);

            } else {

                performance.sort(function (a, b) {
                    return a.resource_name.localeCompare(b.resource_name);
                });
                // performance.sort((a, b) => a.resource_name - b.resource_name);
            }
            this.setState({ name_rev });
        }

        this.setState({ performance })
    }

    detailsDataTableSort = (sort) => {
        let detailPerformance = this.state.detailPerformance
        if (sort == 'estman') {
            let est2_rev = !this.state.est2_rev;
            if (est2_rev) {
                detailPerformance.sort((a, b) => b.estimated_man_day - a.estimated_man_day);

            } else {
                detailPerformance.sort((a, b) => a.estimated_man_day - b.estimated_man_day);
            }
            this.setState({ est2_rev });

        } else if (sort == 'actualman') {
            let actual2_rev = !this.state.actual2_rev;
            if (actual2_rev) {
                detailPerformance.sort((a, b) => b.actual_man_day - a.actual_man_day);

            } else {
                detailPerformance.sort((a, b) => a.actual_man_day - b.actual_man_day);
            }
            this.setState({ actual2_rev });


        } else if (sort == 'estbudget') {
            let estb2_rev = !this.state.estb2_rev;
            if (estb2_rev) {
                detailPerformance.sort((a, b) => b.estimated_budget - a.estimated_budget);

            } else {
                detailPerformance.sort((a, b) => a.estimated_budget - b.estimated_budget);
            }
            this.setState({ estb2_rev });


        } else if (sort == 'actualbudget') {
            let actualb2_rev = !this.state.actualb2_rev;
            if (actualb2_rev) {
                detailPerformance.sort((a, b) => b.actual_budget - a.actual_budget);

            } else {
                detailPerformance.sort((a, b) => a.actual_budget - b.actual_budget);
            }
            this.setState({ actualb2_rev });
        } else if (sort == 'working') {
            let working_rev = !this.state.working_rev;
            if (working_rev) {
                detailPerformance.sort((a, b) => b.working_since - a.working_since);

            } else {
                detailPerformance.sort((a, b) => a.working_since - b.working_since);
            }
            this.setState({ working_rev });
        }

        this.setState({ detailPerformance })
    }

    performanceFilterSubmit = async () => {

        this.setState({ isLoading: true });
        //this.setState({performance:{}})
        var searchData = [];
        console.log(this.state.year);
        searchData.push({ 'fromDate': moment(this.state.fromDate).format('YYYY-MM-DD'), 'toDate': moment(this.state.toDate).format('YYYY-MM-DD'), 'leaderId': this.state.leaderId });
        console.log('filterdata', searchData);
        await this.getperformanceFilter(searchData).then(data => {
            //console.log('API data',data.data);
            this.mappedApiDatWithCalculation(data ? data.data ? data.data : {} : {});
            this.footerTotalCalculation(data ? data.data ? data.data : {} : {});
        });
        this.setState({ isLoading: false });

    }

    detailFilterSubmit = async () => {
        this.setState({ isLoading: true });
        var searchData = [];
        console.log(this.state.year);
        searchData.push({ 'projectId': this.state.projectId, 'leaderId': this.state.leaderId, 'userId': this.state.userId, 'fromDate': moment(this.state.fromDate).format('YYYY-MM-DD'), 'toDate': moment(this.state.toDate).format('YYYY-MM-DD') });
        //console.log("searchData",searchData);
        await this.getDetailFilter(searchData).then(data => {
            //console.log('API data',data.data);
            this.mappedApiDatWithCalculationDetail(data ? data.data ? data.data : {} : {});
            this.footerTotalCalculation(data ? data.data ? data.data : {} : {});
        });
        this.setState({ isLoading: false });
    }

    getTotal = (field = null) => {
        let total = 0
        if (this.state.performance && this.state.performance.length && field) {
            this.state.performance.map(item => {
                total += (item[field] ? +item[field] : 0)
            })
        }
        return total.toFixed(2)
    }

    formatDate = (dt) => {
        const date = new Date(dt)
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
    
        return `${day}${month}, ${year}`;
    }

    render = () => {
        return (
            <div>
                <section id="contents">
                    <Header></Header>
                    <section className="statistics project-tab">
                        <div className="container-fluid">
                            <div className="row">
                                {
                                    (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'Account' || localStorage.getItem('type') == 'Member') ? (
                                        <div className="col-md-2 width-auto2">
                                            <div className="box">
                                                <a href="/project-logs">
                                                    <div className="info">
                                                        <h3>Project Performance</h3>
                                                        <i className="fa fa-star" aria-hidden="true"></i>
                                                    </div></a>
                                            </div>
                                        </div>
                                    ) : ''
                                }
                                {
                                    (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'HR') ? (
                                        <div className="col-md-2 width-auto2 active">
                                            <div className="box" style={{ backgroundColor: '#42ca37' }}>
                                                <a href="/performance">
                                                    <div className="info">
                                                        <h3>Resource Performance</h3>
                                                        <i className="fa fa-star" aria-hidden="true"></i>
                                                    </div></a>
                                            </div>
                                        </div>
                                    ) : ''
                                }
                                {
                                    (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'HR') ? (
                                        <div className="col-md-2 width-auto2">
                                            <div className="box" >
                                                <a href="/wbs-upload">
                                                    <div className="info">
                                                        <h3>WBS Upload</h3>
                                                        <i className="fa fa-star" aria-hidden="true"></i>
                                                    </div></a>
                                            </div>
                                        </div>
                                    ) : ''
                                }

                            </div>
                        </div>
                    </section>
                    <section className="statistics">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="box">
                                        <div className="col-md-12">
                                            <div className="section2">
                                                <div className="row">

                                                    <div className="col-md-2 width-auto">
                                                        <div className="form-group">
                                                            <label>Period </label>
                                                            <select className="form-control form-control-sm" name="period" id="period" onChange={this.periodDropChange} defaultValue={5}>
                                                                <option>Select</option>
                                                                <option value="0">All</option>
                                                                <option value="1">Current Month</option>
                                                                <option value="2">Previous Month</option>
                                                                <option value="3">Current Qtr</option>
                                                                <option value="4">Previous Qtr</option>
                                                                <option value="5">Current FY</option>
                                                                <option value="6">Previous FY</option>
                                                                <option value="7">Custom</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="show_date_filter" style={{ display: 'none' }}>
                                                        <div className='col-md-3 width-auto'>
                                                            <div className="form-group">
                                                                <label>From Date</label>
                                                                <DatePicker selected={this.state.fromDate} onChange={date => this.setState({ fromDate: date })} dateFormat="yyyy-MM-dd" className="form-control form-control-sm form_date" />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-3 width-auto'>
                                                            <div className="form-group">
                                                                <label>To Date</label>
                                                                <DatePicker selected={this.state.toDate} onChange={date => this.setState({ toDate: date })} dateFormat="yyyy-MM-dd" className="form-control form-control-sm to_date" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'Account') ? (
                                                            <div className='col-md-2 width-auto'>
                                                                <div className="form-group">
                                                                    <label>Leader</label>
                                                                    <select className="form-control form-control-sm" name="leader_id" id="leader_id" onChange={(event) => this.setState({ leaderId: event.target.value })}>
                                                                        <option value="">Select leader</option>
                                                                        <option value="">All</option>
                                                                        {
                                                                            (this.state.leaderDrops.length) ? (
                                                                                this.state.leaderDrops.map((ele, i) => (
                                                                                    <option key={i} value={ele.dis_project_lead_id}>{ele.leader_name}</option>
                                                                                ))
                                                                            ) : (
                                                                                <option>No Leader found!</option>
                                                                            )
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )
                                                    }


                                                    <div className='col-md-2 width-auto'>
                                                        <div className="form-group" >
                                                            <button onClick={() => this.performanceFilterSubmit()} className="btn btn-info btn-sm filter-btn">Filter</button>
                                                            <a href="/performance" className="btn btn-sm btn-warning">Clear</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="statistics">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="box">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="">
                                                    <div className="right-box">
                                                        <CSVLink data={this.state.csvData} onClick={() => this.PerformanceCsvDownload()} filename={"ResourcePerformance.csv"} target="_blank" className="btn btn-sm btn-success">Export</CSVLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="table-box new-table-box">
                                                    {/* <button onClick={() => this.showdetail()} className="btn btn-sm btn-info budget_button">Hide Budget</button> */}
                                                    <table className="table table-sm table-responsive table-bordered" id="example">
                                                        <thead className="project_table_th">
                                                            <tr className="main_th">
                                                                <th scope="col" colSpan="3" className="project-header no-border2">Resource</th>

                                                                <th scope="col" colSpan="8" className="mandays-header">Project Man Days</th>

                                                                <th scope="col" colSpan="3" className="budget-header">Budget</th>
                                                                <th scope="col" colSpan="3" className="status-header">Dates</th>

                                                                <th scope="col" colSpan="3" className="ticket-header">Total Tickets</th>
                                                                {/* <th scope="col" colSpan="4" className="projection-header">Projection</th> */}
                                                            </tr>
                                                            <tr className="header-th2">
                                                                <th scope="col" className="project-header">Name
                                                                    <a href="#" onClick={() => this.performanceDataTableSort('name')} className="soCloserSort">
                                                                        {
                                                                            !(this.state.name_rev) ? (
                                                                                <i className="fa fa-arrow-down sort_arrowa" aria-hidden="true"></i>
                                                                            ) : (
                                                                                <i class="fa fa-arrow-up sort_arrowa" aria-hidden="true"></i>

                                                                            )
                                                                        }
                                                                    </a>
                                                                </th>
                                                                <th scope="col" className="project-header">Proposed Burn Down Rate</th>
                                                                <th scope="col" className="project-header">Actual Burn Down Rate</th>
                                                                <th scope="col" className="mandays-header">Calender Days</th>

                                                                <th scope="col" className="mandays-header">Estimated
                                                                    <a href="#" onClick={() => this.performanceDataTableSort('estman')} className="soCloserSort">
                                                                        {
                                                                            !(this.state.est1_rev) ? (
                                                                                <i className="fa fa-arrow-down sort_arrowa" aria-hidden="true"></i>
                                                                            ) : (
                                                                                <i class="fa fa-arrow-up sort_arrowa" aria-hidden="true"></i>

                                                                            )
                                                                        }
                                                                    </a>
                                                                </th>
                                                                <th scope="col" className="mandays-header">Actual on close </th>
                                                                <th scope="col" className="mandays-header">Actual on pending</th>
                                                                <th scope="col" className="mandays-header">Est. on pending</th>
                                                                <th scope="col" className="mandays-header">Actual
                                                                    <a href="#" onClick={() => this.performanceDataTableSort('actualman')} className="soCloserSort">
                                                                        {
                                                                            !(this.state.actual1_rev) ? (
                                                                                <i className="fa fa-arrow-down sort_arrowa" aria-hidden="true"></i>
                                                                            ) : (
                                                                                <i class="fa fa-arrow-up sort_arrowa" aria-hidden="true"></i>

                                                                            )
                                                                        }
                                                                    </a>
                                                                </th>
                                                                <th scope="col" className="mandays-header">Deviation</th>
                                                                <th scope="col" className="mandays-header">% of Deviation</th>


                                                                <th scope="col" className="budget-header">Estimated
                                                                    <a href="#" onClick={() => this.performanceDataTableSort('estbudget')} className="soCloserSort">
                                                                        {
                                                                            !(this.state.estb1_rev) ? (
                                                                                <i className="fa fa-arrow-down sort_arrowa" aria-hidden="true"></i>
                                                                            ) : (
                                                                                <i class="fa fa-arrow-up sort_arrowa" aria-hidden="true"></i>

                                                                            )
                                                                        }
                                                                    </a>
                                                                </th>
                                                                <th scope="col" className="budget-header">Actual
                                                                    <a href="#" onClick={() => this.performanceDataTableSort('actualbudget')} className="soCloserSort">
                                                                        {
                                                                            !(this.state.actualb1_rev) ? (
                                                                                <i className="fa fa-arrow-down sort_arrowa" aria-hidden="true"></i>
                                                                            ) : (
                                                                                <i class="fa fa-arrow-up sort_arrowa" aria-hidden="true"></i>

                                                                            )
                                                                        }
                                                                    </a>
                                                                </th>
                                                                <th scope="col" className="budget-header">Deviation</th>

                                                                <th scope="col" className="status-header">Estimated End</th>
                                                                <th scope="col" className="status-header">Actual End</th>
                                                                <th scope="col" className="status-header">Devi</th>

                                                                <th scope="col" className="ticket-header">Assign</th>
                                                                <th scope="col" className="ticket-header">Close</th>
                                                                <th scope="col" className="ticket-header">Pending</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="new-tbody">
                                                            {
                                                                (this.state.isLoading) ? (<Loader
                                                                    type="TailSpin"
                                                                    color="#00BFFF"
                                                                    height={100}
                                                                    width={100}
                                                                    style={{ justifyContent: 'center', alignItems: 'center' }}
                                                                />) : (this.state.performance) ? (
                                                                    this.state.performance.map((item, i) => (
                                                                        <tr key={i}>
                                                                            <td className="project-header" ><a href="#" onClick={() => this.showdetail(item.user_id, item.resource_name)} >{item.resource_name}</a></td>
                                                                            <td className="project-header" style={{ textAlign: 'center' }}>{item.estimated_man_day_per_tickets || 0}</td>
                                                                            <td className="project-header" style={{ textAlign: 'center' }}>{item.actual_man_day_per_tickets || 0}</td>
                                                                            <td className="mandays-header" style={{ textAlign: 'center' }}>{item.calendar_day || 0} </td>
                                                                            <td className="mandays-header" style={{ textAlign: 'center' }}>{item.estimated_man_day || 0} </td>
                                                                            <td className="mandays-header" style={{ textAlign: 'center' }}>{item.actual_man_day_on_close_tickets || 0}</td>
                                                                            <td className="mandays-header" style={{ textAlign: 'center' }}>{item.actual_man_day_on_pending_tickets || 0}</td>
                                                                            <td className="mandays-header" style={{ textAlign: 'center' }}>{item.pending_tickets_estimated_man_day || 0}</td>
                                                                            <td className="mandays-header" style={{ textAlign: 'center' }}>{item.actual_man_day || 0}</td>
                                                                            <td className="mandays-header" style={{ textAlign: 'center' }}>{item.deviation_in_man_day || 0}</td>
                                                                            <td className="mandays-header" style={{ textAlign: 'center' }}>{item.deviation_in_percentage || 0}</td>


                                                                            <td className="budget-header budget-header-td" style={{ textAlign: 'center' }}>{item.estimated_budget || 0}</td>
                                                                            <td className="budget-header budget-header-td" style={{ textAlign: 'center' }}>{item.actual_budget || 0}</td>
                                                                            <td className="budget-header budget-header-td" style={{ textAlign: 'center' }}>{item.deviation_in_budget ? (item.deviation_in_budget).toFixed(2) : "0.00"}</td>

                                                                            <td className="status-header" style={{ textAlign: 'center' }}>
                                                                                {this.formatDate(item.estimated_end_date)}
                                                                            </td>
                                                                            <td className="status-header" style={{ textAlign: 'center' }}>
                                                                                {this.formatDate(item.actual_end_date)}
                                                                            </td>
                                                                            <td className="status-header" style={{ textAlign: 'center' }}>{item.date_deviation || 0}</td>


                                                                            <td className="ticket-header ticket-header-td" style={{ textAlign: 'center' }}>{item.total_tickets || 0}</td>
                                                                            <td className="ticket-header ticket-header-td" style={{ textAlign: 'center' }}>{item.close_tickets || 0}</td>
                                                                            <td className="ticket-header ticket-header-td" style={{ textAlign: 'center' }}>{item.pending_tickets || 0}</td>

                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="11" style={{ textAlign: 'center' }}> No data found!</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                        <tfoot style={{ 'font-size': '12px' }}>
                                                            <th style={{ textAlign: 'center' }}>Total</th>
                                                            <th style={{ textAlign: 'center' }}>{this.getTotal("estimated_man_day_per_tickets")}</th>
                                                            <th style={{ textAlign: 'center' }}>{this.getTotal("actual_man_day_per_tickets")}</th>
                                                            <th className="mandays-header"></th>
                                                            <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.totalEstMan.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>

                                                            <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.totalActClose.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                            <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.totalActPending.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                            <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.estManPending.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>

                                                            <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.totalActualMan.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                            <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.totalActualdev.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                            <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.totaldevInPercentage.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>


                                                            <th className="budget-header" style={{ textAlign: 'right' }}>{this.state.totalBudget.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                            <th className="budget-header" style={{ textAlign: 'right' }}>{this.state.totalActualBudget.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                            <th className="budget-header" style={{ textAlign: 'right' }}>{(this.state.budgetdeviation).toLocaleString(undefined, { maximumFractionDigits: 2 })
                                                            }</th>
                                                            <th className="status-header" ></th>
                                                            <th className="status-header" ></th>
                                                            <th className="status-header" ></th>
                                                            <th className="ticket-header" style={{ textAlign: 'right' }}>{this.state.totalAssiTicket.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                            <th className="ticket-header" style={{ textAlign: 'right' }}>{this.state.totalCloseTicket.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                            <th className="ticket-header" style={{ textAlign: 'right' }}>{this.state.totalPendingTicket.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

                <div className="modal budget_hide" id="resource_detail_modal" tabindex="-1" role="dialog">
                    <div className="modal-dialog" role="document" style={{ width: "90%", height: "300px" }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.modalTitle}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.showDetailClose()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <section className="statistics">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="box">
                                                    <div className="col-md-12">
                                                        <div className="section2">
                                                            <div className="row">
                                                                <div className="col-md-2 width-auto">
                                                                    <div className="form-group">
                                                                        <label>Period </label>
                                                                        <select className="form-control form-control-sm" name="period" id="period" onChange={this.periodDropChange} >
                                                                            <option>Select</option>
                                                                            <option value="0">All</option>
                                                                            <option value="1">Current Month</option>
                                                                            <option value="2">Previous Month</option>
                                                                            <option value="3">Current Qtr</option>
                                                                            <option value="4">Previous Qtr</option>
                                                                            <option value="5" selected>Current FY</option>
                                                                            <option value="6">Previous FY</option>
                                                                            <option value="7">Custom</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className="show_date_filter" style={{ display: 'none' }}>
                                                                    <div className='col-md-3 width-auto'>
                                                                        <div className="form-group">
                                                                            <label>From Date</label>
                                                                            <DatePicker selected={this.state.fromDate} onChange={date => this.setState({ fromDate: date })} dateFormat="yyyy-MM-dd" className="form-control form-control-sm form_date" />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-3 width-auto'>
                                                                        <div className="form-group">
                                                                            <label>To Date</label>
                                                                            <DatePicker selected={this.state.toDate} onChange={date => this.setState({ toDate: date })} dateFormat="yyyy-MM-dd" className="form-control form-control-sm to_date" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='col-md-2 width-auto'>
                                                                    <div className="form-group">
                                                                        <label>Project</label>
                                                                        <select className="form-control form-control-sm" name="leader_id" id="leader_id" onChange={(event) => this.setState({ projectId: event.target.value })}>
                                                                            <option value="">Select Project</option>
                                                                            <option value="">All</option>
                                                                            {
                                                                                (this.state.projectDrops.length) ? (
                                                                                    this.state.projectDrops.map((ele, i) => (
                                                                                        <option key={i} value={ele.project_id}>{ele.project_name}</option>
                                                                                    ))
                                                                                ) : (
                                                                                    <option>No Project found!</option>
                                                                                )
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'Account') ? (
                                                                        <div className='col-md-2 width-auto'>
                                                                            <div className="form-group">
                                                                                <label>Leader</label>
                                                                                <select className="form-control form-control-sm" name="leader_id" id="leader_id" onChange={(event) => this.setState({ leaderId: event.target.value })}>
                                                                                    <option value="">Select leader</option>
                                                                                    <option value="">All</option>
                                                                                    {
                                                                                        (this.state.leaderDrops.length) ? (
                                                                                            this.state.leaderDrops.map((ele, i) => (
                                                                                                <option key={i} value={ele.leader_id}>{ele.leader_name}</option>
                                                                                            ))
                                                                                        ) : (
                                                                                            <option>No Leader found!</option>
                                                                                        )
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        ''
                                                                    )
                                                                }


                                                                <div className='col-md-2 width-auto'>
                                                                    <div className="form-group" >
                                                                        <button onClick={() => this.detailFilterSubmit()} className="btn btn-info btn-sm filter-btn">Filter</button>
                                                                        <a href="/performance" className="btn btn-sm btn-warning">Clear</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section className="statistics">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="right-box">
                                                            <CSVLink data={this.state.csvDataDetails} onClick={() => this.DetailsCsvDownload()} filename={this.state.modalTitle + ".csv"} target="_blank" className="btn btn-sm btn-success pull-right">Export</CSVLink>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="table-box">
                                                            <table className="table table-sm table-responsive table-bordered modal_table modal_tbl_resorce" id="example">
                                                                <thead className="project_table_th">
                                                                    <tr className="main_th">
                                                                        <th scope="col" colSpan="2" className="project-header">Project  & Leader</th>

                                                                        <th scope="col" colSpan="7" className="mandays-header">Project Man Days</th>

                                                                        <th scope="col" colSpan="3" className="budget-header">Budget</th>

                                                                        <th scope="col" colSpan="3" className="ticket-header">Total Tickets</th>
                                                                        {/* <th scope="col" colSpan="4" className="projection-header">Projection</th> */}
                                                                    </tr>
                                                                    <tr className="header-th2">
                                                                        <th scope="col" className="project-header">Leader</th>
                                                                        <th scope="col" className="project-header">Project</th>


                                                                        <th scope="col" className="mandays-header">Estimated
                                                                            <a href="#" onClick={() => this.detailsDataTableSort('estman')} className="soCloserSort">
                                                                                {
                                                                                    !(this.state.est1_rev) ? (
                                                                                        <i className="fa fa-arrow-down sort_arrowa" aria-hidden="true"></i>
                                                                                    ) : (
                                                                                        <i class="fa fa-arrow-up sort_arrowa" aria-hidden="true"></i>

                                                                                    )
                                                                                }
                                                                            </a>
                                                                        </th>
                                                                        <th scope="col" className="mandays-header">Actual on close </th>
                                                                        <th scope="col" className="mandays-header">Actual on pending</th>
                                                                        <th scope="col" className="mandays-header">Est. on pending</th>
                                                                        <th scope="col" className="mandays-header">Actual
                                                                            <a href="#" onClick={() => this.detailsDataTableSort('actualman')} className="soCloserSort">
                                                                                {
                                                                                    !(this.state.actual1_rev) ? (
                                                                                        <i className="fa fa-arrow-down sort_arrowa" aria-hidden="true"></i>
                                                                                    ) : (
                                                                                        <i class="fa fa-arrow-up sort_arrowa" aria-hidden="true"></i>

                                                                                    )
                                                                                }
                                                                            </a>
                                                                        </th>
                                                                        <th scope="col" className="mandays-header">Deviation</th>
                                                                        <th scope="col" className="mandays-header">% of Deviation</th>


                                                                        <th scope="col" className="budget-header">Estimated
                                                                            <a href="#" onClick={() => this.detailsDataTableSort('estbudget')} className="soCloserSort">
                                                                                {
                                                                                    !(this.state.estb1_rev) ? (
                                                                                        <i className="fa fa-arrow-down sort_arrowa" aria-hidden="true"></i>
                                                                                    ) : (
                                                                                        <i class="fa fa-arrow-up sort_arrowa" aria-hidden="true"></i>

                                                                                    )
                                                                                }
                                                                            </a>
                                                                        </th>
                                                                        <th scope="col" className="budget-header">Actual
                                                                            <a href="#" onClick={() => this.detailsDataTableSort('actualbudget')} className="soCloserSort">
                                                                                {
                                                                                    !(this.state.actualb1_rev) ? (
                                                                                        <i className="fa fa-arrow-down sort_arrowa" aria-hidden="true"></i>
                                                                                    ) : (
                                                                                        <i class="fa fa-arrow-up sort_arrowa" aria-hidden="true"></i>

                                                                                    )
                                                                                }
                                                                            </a>
                                                                        </th>
                                                                        <th scope="col" className="budget-header">Deviation</th>

                                                                        <th scope="col" className="ticket-header">Assign</th>
                                                                        <th scope="col" className="ticket-header">Close</th>
                                                                        <th scope="col" className="ticket-header">Pending</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="new-tbody">
                                                                    {
                                                                        (this.state.isLoading) ? (<Loader
                                                                            type="TailSpin"
                                                                            color="#00BFFF"
                                                                            height={100}
                                                                            width={100}
                                                                            style={{ justifyContent: 'center', alignItems: 'center' }}
                                                                        />) : this.state.detailPerformance && this.state.detailPerformance.length ? (
                                                                            this.state.detailPerformance.map((item, i) => (
                                                                                <tr key={i}>
                                                                                    <td className="project-header" >{item.leader_name}</td>
                                                                                    <td className="project-header">{item.project_name}</td>

                                                                                    <td className="mandays-header">{item.estimated_man_day || 0} </td>
                                                                                    <td className="mandays-header">{item.actual_man_day_on_close_tickets || 0}</td>
                                                                                    <td className="mandays-header">{item.actual_man_day_on_pending_tickets || 0}</td>
                                                                                    <td className="mandays-header">{item.pending_tickets_estimated_man_day || 0}</td>
                                                                                    <td className="mandays-header">{item.actual_man_day || 0}</td>
                                                                                    <td className="mandays-header">{item.deviation_in_man_day || 0}</td>
                                                                                    <td className="mandays-header">{item.deviation_in_percentage || 0}</td>


                                                                                    <td className="budget-header">{item.estimated_budget || 0}</td>
                                                                                    <td className="budget-header">{item.actual_budget || 0}</td>
                                                                                    <td className="budget-header">{item.deviation_in_budget || 0}</td>

                                                                                    <td className="ticket-header">{item.total_tickets || 0}</td>
                                                                                    <td className="ticket-header">{item.close_tickets || 0}</td>
                                                                                    <td className="ticket-header">{item.pending_tickets || 0}</td>

                                                                                </tr>
                                                                            ))
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan="11"> No data found!</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                </tbody>
                                                                <tfoot style={{ 'font-size': '12px' }}>
                                                                    <th style={{ textAlign: 'center' }}>Total</th>
                                                                    <th></th>

                                                                    <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.totalEstMan.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>

                                                                    <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.totalActClose.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                                    <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.totalActPending.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                                    <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.estManPending.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>

                                                                    <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.totalActualMan.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                                    <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.totalActualdev.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                                    <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.totaldevInPercentage.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>


                                                                    <th className="budget-header" style={{ textAlign: 'right' }}>{this.state.totalBudget.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                                    <th className="budget-header" style={{ textAlign: 'right' }}>{this.state.totalActualBudget.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                                    <th className="budget-header" style={{ textAlign: 'right' }}>{(this.state.budgetdeviation).toLocaleString(undefined, { maximumFractionDigits: 2 })
                                                                    }</th>

                                                                    <th className="ticket-header" style={{ textAlign: 'right' }}>{this.state.totalAssiTicket.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                                    <th className="ticket-header" style={{ textAlign: 'right' }}>{this.state.totalCloseTicket.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                                    <th className="ticket-header" style={{ textAlign: 'right' }}>{this.state.totalPendingTicket.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>



                            </div>
                            {/* <div class="modal-footer">
                                <button type="button" class="btn btn-primary">Save changes</button>
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

        );
    }


}
export default ResourcePermormace;